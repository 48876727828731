import React, { useEffect, useState } from "react";
import Hero from "../home/hero";
import Item from "../home/item";
import { useItemStore } from "../../store/useItemStore";
import NoData from "../reuse/NoData";
import BidDetails from "../home/BidDetails";
import { useBidStore } from "../../store/useBidStore";

const Home = () => {
  const { liveItems } = useItemStore();
  const { isCloseBid, setIsCloseBid } = useBidStore();
  useEffect(() => {
    isCloseBid && setTimeout(() => setIsCloseBid(false), 5000);
  }, [isCloseBid]);
  return (
    <>
      {isCloseBid ? (
        <main className="fixed">
          <img src="/winner.gif" className="h-[105vh] w-full" />
        </main>
      ) : (
        <>
          <Hero />
          {liveItems?.length > 0 ? <Item /> : <NoData type={"Produkte"} />}
        </>
      )}
    </>
  );
};

export default Home;
