import { create } from "zustand";

export const useBidStore = create((set) => ({
  isCloseBid: false,
  setIsCloseBid: (data) => set({ isCloseBid: data }),
  allBids: [],
  allBidsByLiveItemID: [],
  updateAllBids: (data) => set({ allBids: data }),
  updateAllBidsByLiveItemID: (data) => set({ allBidsByLiveItemID: data }),
}));
